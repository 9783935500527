
import { Component, Vue } from 'vue-property-decorator';
import GuideItem from "@/components/GuideItem.vue";
import {fetchBlogPostList, Page} from "@/serviceClients/pageWebServices";
import {getBaseImagesUrl} from "@/helpers/settingsHelper";
import {pushError} from "@/core";

@Component({
  components: {
    GuideItem
  }
})
export default class Blog extends Vue {
  isLoading = false;

  entries: Page[] = [];

  get imagesUrl() {
    return getBaseImagesUrl();
  }

  mounted() {
    this.isLoading = true;

    fetchBlogPostList()
      .then((resp) => {
        this.entries = resp.data;
      })
      .finally(() => {
        this.isLoading = false;
      })
      .catch((error) => {
        pushError(error.response?.data?.error || error);
      });
  }
  get breadcrumbsList() {
    return  [
      {
        text: this.$t('Home'),
        href: '/'
      },
      {
        text: this.$t('Blog'),
        href: '#'
      }
    ]
  }
}
