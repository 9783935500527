

import { fetchBlogPost, fetchPage, Page } from '../serviceClients/pageWebServices';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { pushError } from '@/core';

@Component
export default class BlogPost extends Vue {
  isLoading = false;

  page: Page = {} as Page;

  get pageText() {
    return this.page?.text;
  }

  get pageTitle() {
    return this.page?.title;
  }

  mounted() {
    this.idChanged();
    this.$watch(() => this.$route.params.id, () => {
      this.idChanged();
    })
  }

  idChanged() {
    this.page = {} as Page;
    this.isLoading = true;
    fetchBlogPost(this.$route.params.id)
      .then((resp) => {
        this.page = resp.data;
      })
      .finally(() => {
          this.isLoading = false;
      })
      .catch((error) => {
          pushError(error.response?.data?.error || error);
      });
  }
}
